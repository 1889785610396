export const normalDisOption = {
  tooltip: {
    show: false
  },
  legend: {
    data: ['区间频次', '正态曲线'],
    left: 'center',
    top: 'bottom'
  },
  xAxis: [
    {
      type: 'category',
      data: [],
      axisPointer: {
        type: 'shadow'
      },
      splitArea: {
        //--网格区域
        show: false //---是否显示，默认false
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      min: 0,
      max: 10,
      interval: 1,
      splitLine: {
        show: false // 不显示切割线
      },
      axisLabel: {
        formatter: '{value}'
      }
    },
    {
      type: 'value',
      splitLine: {
        show: false
      },
      axisLabel: {
        formatter: '{value}'
      }
    }
  ],
  series: [
    {
      name: '区间频次',
      type: 'bar',
      barWidth: 22, // 柱条的宽度
      data: [0],
      itemStyle: {
        normal: {
          label: { show: true, formatter: '{c}%' },
          color: '#218ACE'
        }
      }
    },
    {
      name: '正态曲线',
      type: 'line',
      yAxisIndex: 1,
      itemStyle: {
        color: '#E67E36'
      },
      smooth: true, // 是否设置平滑曲线
      data: [0]
    }
  ]
}

export const histogrOp = {
  title: {
    text: '平均掌握度',
    left: 'center',
    textStyle: {
      color: '#fff'
    },
    top: 15
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    },
    formatter: function(type) {
      let a = ''
      let b = ''
      a = type[0].axisValue
      b = type[0].data + '%'
      return a + '<br />' + b
    }
  },
  legend: {
    data: ['2011年', '2012年'],
    textStyle: { color: '#fff' },
    left: 'center',
    top: 'bottom'
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    boundaryGap: [0, 0.01],
    axisLine: {
      lineStyle: {
        color: '#fff',
        width: 2
      }
    }
  },
  yAxis: {
    type: 'category',
    data: [],
    axisLine: {
      lineStyle: {
        color: '#fff',
        width: 2
      }
    }
  },
  series: [
    {
      name: '2011年',
      type: 'bar',
      data: [18203, 23489, 29034, 104970, 131744, 630230],
      label: {
        show: true,
        position: 'right',
        color: '#fff'
      }
    }
  ]
}

export const questionTypeBar = {
  title: {
    text: '总分：150分',
    left: 'center',
    top: -4
  },
  tooltip: {
    show: false,
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)'
  },
  legend: {
    orient: 'vertical',
    data: []
  },
  series: [
    {
      name: '分数占比',
      type: 'pie',
      radius: ['50%', '65%'],
      center: ['50%', '55%'],
      avoidLabelOverlap: false,
      itemStyle: {
        color: '#218ACE',
        normal: {
          label: {
            textStyle: {
              color: '#333'
            },
            formatter: '{b}:{c}%'
          },
          labelLine: {
            show: true,
            lineStyle: {
              color: '#333'
            }
          }
        }
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '12',
          fontWeight: 'bold'
        }
      },
      data: []
    }
  ]
}
